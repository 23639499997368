import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function Seo({ pathname = '', title, description, imageUrl, meta = [] }) {
  const {
    contentfulGlobals: {
      siteUrl,
      seoTitle,
      seoSiteDescription: { seoSiteDescription },
      seoShareImage,
      twitterUsername,
    },
  } = useStaticQuery(
    graphql`
      query {
        contentfulGlobals {
          siteUrl
          seoTitle
          seoSiteDescription {
            seoSiteDescription
          }
          seoShareImage {
            file {
              url
            }
          }
          twitterUsername
        }
      }
    `
  )

  const metaTitle = title ? `${title} | ${seoTitle}` : seoTitle
  const metaDescription = description || seoSiteDescription
  const metaImage = imageUrl || `https:${seoShareImage.file.url}`
  const url = `${siteUrl}${pathname}/`
  const metaTags = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:image`,
      content: metaImage,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:url`,
      content: url,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]

  if (twitterUsername) {
    metaTags.push({
      name: `twitter:site`,
      content: `@${twitterUsername}`,
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={metaTitle}
      meta={[...metaTags, ...meta]}
      link={[{ rel: 'canonical', href: url }]}
    />
  )
}

Seo.propTypes = {
  pathname: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default Seo
