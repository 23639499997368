import React from 'react'
import PropTypes from 'prop-types'
import { CardsMain } from './index.style'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import ArticleCard from '../../ArticleCard'
import { getResourcesTag } from '../../../utils/utils'

const Cards = ({ items }) => (
  <CardsMain>
    <Container>
      <Grid>
        {React.Children.toArray(
          items.map((post, postIndex) => (
            <GridItem
              tabletP={8}
              desk={7}
              deskStart={postIndex % 2 === 0 ? 2 : undefined}
            >
              <ArticleCard
                label={getResourcesTag(post.metadata)}
                title={post.title}
                titleIcon={post.titleIcon}
                excerpt={post.excerpt.excerpt}
                to={`/resources/${post.slug}/`}
              />
            </GridItem>
          ))
        )}
      </Grid>
    </Container>
  </CardsMain>
)

Cards.propTypes = {
  items: PropTypes.array.isRequired,
}

export default Cards
