import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SearchBarMain, SearchBarSubmit } from './index.style'
import Input from '../Input'
import Button from '../Button'

const SearchBar = ({
  to = '/resources/search',
  onChange,
  label,
  placeholder = '',
}) => {
  const [searchQuery, setsearchQuery] = useState(placeholder)

  const handleChange = e => {
    const value = e.currentTarget.value

    setsearchQuery(value)

    if (onChange) {
      onChange(value)
    }
  }

  return (
    <SearchBarMain>
      <Input
        id="resources-search"
        name="resources-search"
        label={label}
        autoComplete="off"
        value={searchQuery}
        onChange={handleChange}
      />

      <SearchBarSubmit>
        <Button
          type="internalLink"
          to={`${to}?query=${searchQuery}`}
          disabled={!searchQuery}
        >
          Search
        </Button>
      </SearchBarSubmit>
    </SearchBarMain>
  )
}

SearchBar.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

export default SearchBar
