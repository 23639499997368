import React from 'react'
import PropTypes from 'prop-types'
import { PaginationButtonMain } from './index.style'
import Button from '../Button'
import IconArrowheadLeft from '../svgs/IconArrowheadLeft'
import IconArrowheadRight from '../svgs/IconArrowheadRight'
import { TextBodySmall } from '../TextStyles'
import AnimateSplitText from '../animation/AnimateSplitText'
import AnimateFadeIn from '../animation/AnimateFadeIn'

const PaginationButton = ({ back = true, onClick, to, children }) => (
  <PaginationButtonMain>
    {back ? (
      <>
        <AnimateFadeIn>
          <Button
            type={to ? 'internalLink' : 'button'}
            variant="secondary"
            to={to}
            onClick={onClick}
            round
            roundIconWidth="11.6721311475%"
            fill
          >
            <IconArrowheadLeft />
          </Button>
        </AnimateFadeIn>

        <TextBodySmall TextBodySmall>
          <AnimateSplitText>{children}</AnimateSplitText>
        </TextBodySmall>
      </>
    ) : (
      <>
        <TextBodySmall>
          <AnimateSplitText>{children}</AnimateSplitText>
        </TextBodySmall>

        <AnimateFadeIn>
          <Button
            type={to ? 'internalLink' : 'button'}
            variant="secondary"
            to={to}
            onClick={onClick}
            round
            roundIconWidth="11.6721311475%"
            fill
          >
            <IconArrowheadRight />
          </Button>
        </AnimateFadeIn>
      </>
    )}
  </PaginationButtonMain>
)

PaginationButton.propTypes = {
  back: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default PaginationButton
