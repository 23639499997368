import { transparentize } from 'polished'
import styled, { css } from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'

const fontSizeMax = 28
const inputTextStyles = css`
  ${clamp('font-size', 20, fontSizeMax)}
  line-height: 1.2;
  letter-spacing: -0.01em;
`

const horizontalPadding = `${50 / fontSizeMax}em`

export const InputWrapper = styled.div`
  position: relative;
`

export const InputMain = styled.input`
  appearance: none;
  display: block;
  width: 100%;
  padding: ${44 / fontSizeMax}em ${horizontalPadding} ${22 / fontSizeMax}em;
  ${inputTextStyles}
  border: none;
  ${clamp('border-radius', 10, 20)}
  background-color: ${transparentize(0.95, colors.dark)};
`

export const InputLabel = styled.label`
  position: absolute;
  top: 50%;
  left: ${horizontalPadding};
  transform-origin: 0% 0%;
  transform: translateY(-50%);
  ${inputTextStyles}
  transition: transform 0.15s ease, opacity 0.15s ease;
  will-change: transform, opacity;

  ${InputWrapper}:focus-within &,
  ${InputMain}:not(:placeholder-shown) + & {
    transform: translateY(-86%) scale(0.6);
    opacity: 0.6;
  }
`
