import React from 'react'

const IconArrowheadLeft = ({
  width = 8,
  height = 12,
  fill = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 12"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="m6.293.293 1.414 1.414L3.414 6l4.293 4.293-1.414 1.414L.586 6 6.293.293Z"
      />
    </svg>
  )
}

export default IconArrowheadLeft
