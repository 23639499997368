import React, { useState } from 'react'
import { graphql } from 'gatsby'
import queryString from 'query-string'
import Seo from '../../components/Seo'
import Container from '../../components/Container'
import PaginationButton from '../../components/PaginationButton'
import Grid from '../../components/Grid'
import GridItem from '../../components/GridItem'
import Spacer from '../../components/Spacer'
import SearchBar from '../../components/SearchBar'
import SearchResults from '../../components/Resources/SearchResults'

const ResourcesSearch = ({ data, location }) => {
  const { seoTitle: resourcesTitle, slug: resourcesSlug } =
    data.contentfulResources
  const { query } = queryString.parse(location.search)
  const [searchQuery, setSearchQuery] = useState(query)

  const handleQueryChange = value => {
    setSearchQuery(value)
  }

  return (
    <>
      <Seo
        title="Resources Search"
        description={'Search the Ember Fund Resources'}
        pathname={`${resourcesSlug}/search`}
      />

      <Container>
        <Spacer size={[170, 200]} />

        <Grid>
          <GridItem desk={14} deskStart={2}>
            <PaginationButton to={resourcesSlug}>
              {resourcesTitle}
            </PaginationButton>

            <Spacer size={[24, 40]} />

            <SearchBar
              onChange={handleQueryChange}
              label="Search again"
              placeholder={query ? query : ''}
            />
          </GridItem>
        </Grid>
      </Container>

      <SearchResults searchQuery={searchQuery} />

      <Spacer size={[120, 160]} />
    </>
  )
}

export default ResourcesSearch

export const pageQuery = graphql`
  query ResourcesSearch {
    contentfulResources {
      seoTitle
      slug
    }
  }
`
