import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'

export const PaginationButtonMain = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  > * + * {
    ${clamp('margin-left', 20, 25)}
  }

  p {
    pointer-events: none;
  }
`
