import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Spacer from '../../Spacer'
import Cards from '../Cards'
import { useFlexSearch } from 'react-use-flexsearch'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import { Heading2, TextBody } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import AnimateSlideIn from '../../animation/AnimateSlideIn'

const SearchResults = ({ searchQuery }) => {
  const {
    localSearchResources: { index, store },
  } = useStaticQuery(graphql`
    query {
      localSearchResources {
        index
        store
      }
    }
  `)
  const results = useFlexSearch(searchQuery, index, store)

  return (
    <>
      {results.length ? (
        <>
          <Spacer size={[24, 40]} />
          <Cards items={results} />
        </>
      ) : (
        <Container>
          <Grid>
            <GridItem desk={7} deskStart={2}>
              <Spacer size={[40, 80]} />

              <Heading2 as={`h1`}>
                <AnimateSlideIn>No results for "{searchQuery}"</AnimateSlideIn>
              </Heading2>

              <Spacer size={[12, 24]} />

              <TextBody>
                <AnimateSplitText>
                  Maybe you may have misspelled the word or or that word does
                  not exist in our database. Try again! If you have some other
                  questions contact us at{' '}
                  <a href="mailto:help@emberfund.io">help@emberfund.io</a>.
                </AnimateSplitText>
              </TextBody>
            </GridItem>
          </Grid>
        </Container>
      )}
    </>
  )
}

SearchResults.propTypes = {
  searchQuery: PropTypes.string,
}

export default SearchResults
