import React from 'react'
import PropTypes from 'prop-types'
import { InputLabel, InputMain, InputWrapper } from './index.style'

const Input = ({
  type,
  id,
  name,
  label,
  value,
  disabled,
  required,
  onChange,
  ...props
}) => (
  <InputWrapper>
    <InputMain
      type={type}
      id={id}
      name={name}
      placeholder=" "
      value={value}
      onChange={onChange}
      disabled={disabled}
      required={required}
      {...props}
    />

    <InputLabel htmlFor={id}>{label}</InputLabel>
  </InputWrapper>
)

Input.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
}

Input.defaultProps = {
  type: 'text',
}

export default Input
