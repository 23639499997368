import React from 'react'

const IconArrowheadRight = ({
  width = 8,
  height = 12,
  fill = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 12"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 11.707.586 10.293 4.879 6 .586 1.707 2 .293 7.707 6 2 11.707Z"
      />
    </svg>
  )
}

export default IconArrowheadRight
