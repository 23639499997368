import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { mq } from '../../styles/vars/media-queries.style'
import { ButtonMain } from '../Button/index.style'

export const SearchBarMain = styled.div`
  position: relative;
`

export const SearchBarSubmit = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  ${clamp('right', 15, 26)}
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 200px;

  ${ButtonMain} {
    ${mq.tabletPMax} {
      padding-left: 1.5625em;
      padding-right: 1.5625em;
    }
  }
`
